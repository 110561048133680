import {
  Locale,
  Button,
  Toast,
  ShareSheet,
  NavBar,
  Tab,
  Tabs,
  Form,
  Field,
  Cell,
  CellGroup,
  Uploader,
  Dialog,
  Popup,
  Overlay,
  Switch,
} from "vant";

import zhTW from "vant/es/locale/lang/zh-TW";

Locale.use("zh-TW", zhTW);

export function vant(app) {
  app
    .use(Button)
    .use(Toast)
    .use(ShareSheet)
    .use(NavBar)
    .use(Tab)
    .use(Tabs)
    .use(Form)
    .use(Field)
    .use(Cell)
    .use(CellGroup)
    .use(Uploader)
    .use(Dialog)
    .use(Popup)
    .use(Overlay)
    .use(Switch);
}
