<template>
    <router-view v-slot="{ Component }">
        <template v-if="$route.meta.keepAlive">
            <KeepAlive>
                <component :is="Component" />
            </KeepAlive>
        </template>
        <template v-else>
            <component :is="Component" />
        </template>
    </router-view>
</template>

<script>

</script>

<style lang="less">
</style>
