<template>
  <div class="home" v-cloak>
    <div class="my-account">
      <div class="account-bg set_up">
        <img src="@/assets/images/topbg.png" />
      </div>
      <div class="avatar_box set_up">
        <img class="avater" :src="userInfo.line_picture || import('@/assets/images/user.jpg')" />
      </div>
      <div class="user-info">
        <p class="uname set_up">UTel電子名片系統</p>
        <a href="javascript:;">
          <p class="umoney phone">歡迎來到會員中心</p>
        </a>
      </div>
      <div class="set" @click="handleLogout">
        <a href="javascript:void(0)">登出</a>
      </div>
    </div>
    <div class="recommend">
      <ul>
        <li @click="router.push('/member')">
          <p>修改資料</p>
          <span>修改名片資料</span>
        </li>
        <li @click="goCardEdit">
          <p>商務卡片</p>
          <span>設計專屬名片</span>
        </li>
        <li @click="router.push('/shop')">
          <p>立即購買</p>
          <span>付費開通方案</span>
        </li>
        <li @click="handleShowNfc">
          <p>展示二維碼</p>
          <span>提供好友掃描</span>
        </li>
      </ul>
    </div>
    <dl class="dl02" v-if="userInfo">
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-01.png" />
          <div class="left">建立日期</div>
          <div class="right">{{ userInfo.create_time }}</div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-02.png" />
          <div class="left">會員編號</div>
          <div class="right">{{ userInfo.user_id }}
            <span class="cp-btn" @click="doCopyUid">複製</span>
          </div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-02.png" />
          <div class="left">公司名稱</div>
          <div class="right">{{ userInfo.company }}</div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-03.png" />
          <div class="left">姓名</div>
          <div class="right">{{ userInfo.real_name }}</div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-04.png" />
          <div class="left">會員等級</div>
          <div class="right">{{ userInfo.level_name }}</div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-05.png" />
          <div class="left">使用期限</div>
          <div class="right">{{ overdue }}</div>

          <!-- <div class="right" @click="router.push('/shop/inputsn')" v-if="!is_due">{{overdue}}<br/>點此輸入購買序號</div>
          <div class="right input-sn" @click="router.push('/shop/inputsn')" v-else>已到期,<br/>點此輸入購買序號</div> -->
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-06.png" />
          <div class="left">名片連結</div>
          <div class="right" style="color: #fe6867" @click="doCopy">
            點擊複製您的連結
          </div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;">
        <div class="menu" @click="$router.push('/auth/auth')">
          <img class="titleImg" src="@/assets/images/icon-04.png" />
          <div class="left">授權商務卡片編輯</div>
          <div class="right">授權</div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;" @click="$router.push('/auth/getauth')">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-04.png" />
          <div class="left">代客編輯商務卡片</div>
          <div class="right">編輯</div>
          <div style="clear: both"></div>
        </div>
      </a>
      <a href="javascript:;" v-if="userInfo.uniqid">
        <div class="menu">
          <img class="titleImg" src="@/assets/images/icon-06.png" />
          <div class="left">綁定UTel淘金購會員</div>
          <div class="right" style="color: #fe6867" @click="bindTggo">
            點擊後前往綁定
          </div>
          <div style="clear: both"></div>
        </div>
      </a>
    </dl>
    <Footer />
    <van-dialog v-model:show="showNfcQrcode" title="電子名片二維碼" :show-cancel-button="true" cancel-button-text="關閉"
      :show-confirm-button="false">
      <div class="qrcode">
        <qrcode-vue :value="userInfo.nfcurl" size="200" level="M" />
      </div>
    </van-dialog>
  </div>
</template>

<script setup>
import liff from "@line/liff"
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'

import { toClipboard } from '@soerenmartius/vue3-clipboard'

import { ref, computed, onBeforeMount, nextTick } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { Toast } from 'vant'
import { Dialog } from 'vant';

import Footer from '@/components/Footer'

import { login , bindCard , extendCard } from '@/api'

const router = useRouter()
const route = useRoute()
const store = useStore()

const showShare = ref(false)

const showNfcQrcode = ref(false)

const imageUrl = ref(process.env.VUE_APP_IMAGE_URL)

const userLevel = {
  0: '基礎款',
  1: '標準款',
  2: '自製款',
  3: '客製款'
}

const is_due = ref(false)

onBeforeMount(async () => {
  try {
    await liff.init({ liffId: process.env.VUE_APP_LINE_LIFF_ID });
    if (!liff.isLoggedIn())
      liff.login({ redirectUri: window.location.href });
  } catch (err) {
    console.log(`liff.state init error ${err}`);
    Toast('登入失敗。請聯絡管理員')
    router.push('/login')
  }


  if (!sessionStorage.getItem('token')) {
    const profile = await liff.getProfile()

    const id_token = liff.getIDToken()


    let loginRes = await login({ line_id: profile.userId, token: id_token })

    if (loginRes.code === 200) {
      if (route.query.act === 'openright') {
        if (route.query.verify) {
          //詢問是否綁定
          Dialog.confirm({
            title: '卡片延期',
            message: '確認是否延期這張卡片'
          }).then(async ()=>{
            //確認延期
            let extendRes = await extendCard({ uid: loginRes.data.uid , verify: route.query.verify })
            if(extendRes.code === 200){
              Toast.success('延期成功')
            }else{
              Toast.fail('延期失敗')
            }
          }).catch(()=>{

          })
        }
      }
      sessionStorage.setItem('token', loginRes.data.token)
      sessionStorage.setItem('uid', loginRes.data.uid)

    } else if (loginRes.code === 202) {
      if (route.query.act === 'openright') {
        if (route.query.verify) {
          //詢問是否綁定
          Dialog.confirm({
            title: '卡片綁定',
            message: '確認是否綁定這張卡片'
          }).then(async ()=>{
            //確認綁定
            let bindRes = await bindCard({ uid: loginRes.data.uid , verify: route.query.verify })
            if(bindRes.code === 200){
              Toast.success('綁定成功')
            }else{
              Toast.fail('綁定失敗')
            }
          }).catch(()=>{
            
          })
        }
      }

      sessionStorage.setItem('token', loginRes.data.token)
      sessionStorage.setItem('uid', loginRes.data.uid)

    } else if (loginRes.code === 201) {
      if (route.query.act === 'openright') {
        if (route.query.verify) {
          router.push({
            path: '/register',
            query: {
              verify: route.query.verify
            }
          })

          return
        }
      } else if (route.query.aid) {
        router.push({
          path: '/register',
          query: {
            aid: route.query.aid
          }
        })
        return
      } else {
        Toast('請先註冊成為本站會員')
        return router.push({
          path: '/register',
          query: { refer: route.query.refer }
        })
      }



    } else {
      Toast('登入失敗。請聯絡管理員')
      router.push('/login')
    }
  }

  store.dispatch('user/getUserInfo')

  //判斷是否過期
  nextTick(() => {

    // if(userInfo.value.level===0 && userInfo.value.nc_type===0){
    //         Toast('會員已過期')
    //         return router.push('/shop/inputsn')
    // }
  })
})


const userInfo = computed(() => {
  return store.state.user.userInfo
})

const nfcurl = computed(() => {
  return encodeURI(store.state.user.userInfo.ufcurl)
})

const overdue = computed(() => {
  if (store.state.user.userInfo.overdue_time > 0) {
    return moment.unix(store.state.user.userInfo.overdue_time).format('YYYY-MM-DD')
  } else {
    return '無期限'
  }
})

const goCardEdit = () => {
  if (userInfo.value.nc_type < 2) {
    router.push('/card/notice')
  } else {
    router.push('/card/edit')
  }
}

const onSelect = (option) => {
  Toast(option.name);
  showShare.value = false;
};

const handleShowNfc = () => {
  showNfcQrcode.value = true
}

const doCopy = () => {
  toClipboard(userInfo.value.nfcurl)
  Toast('已放入剪貼簿')
}

const doCopyUid = () => {
  toClipboard(userInfo.value.user_id)
  Toast('已放入剪貼簿')
}

const bindTggo = () => {
  console.log(userInfo.value)
  let url = `https://www.tggo.com.tw/u.cgi?&mnm=mybinding&ncode=${userInfo.value.uniqid}&name=${userInfo.value.real_name}&openExternalBrowser=1`;

  window.open(url,'_blank');
}

const handleLogout = () => {
  if (liff.isLoggedIn()) {
    liff.logout();
  }
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('uid')
  router.push('/login')
}


</script>

<style src="@/assets/css/style.css">
</style>
<style lang="less" scoped>
[ v-cloak] {
  display: none;
}

.recommend {
  width: 100%;
  padding-top: 2%;
}

.recommend ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recommend ul li {
  box-sizing: content-box;

  width: 44%;
  height: 50px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  margin: 1%;
  -webkit-box-shadow: 0 0 2px #fdaf00;
  -moz-box-shadow: 0 0 2px #fdaf00;
  box-shadow: 0 0 2px #fdaf00;
}

.recommend ul li p {
  margin-top: 2%;
  font-size: 15px;
  color: #333;
}

.recommend ul li span {
  font-size: 14px !important;
  color: #999 !important;
  padding-top: 0.15rem !important;
}

.recommend ul li:nth-child(1) {
  background: url(~@/assets/images/icon-001.png) right 0.3rem center no-repeat #fff !important;
  background-size: 30px !important;
}

.recommend ul li:nth-child(2) {
  background: url(~@/assets/images/icon-002.png) right 0.3rem center no-repeat #fff !important;
  background-size: 30px !important;
}

.recommend ul li:nth-child(3) {
  background: url(~@/assets/images/icon-003.png) right 0.3rem center no-repeat #fff !important;
  background-size: 30px !important;
}

.recommend ul li:nth-child(4) {
  background: url(~@/assets/images/icon-004.png) right 0.3rem center no-repeat #fff !important;
  background-size: 30px !important;
}

.qrcode {
  text-align: center;
}

.input-sn {
  color: red !important;
}

.cp-btn{
  border: #999 1px solid;
  padding: 2px;
  color: rgb(254, 104, 103);
}
</style>