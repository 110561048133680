<template>
  <footer class="footerMenu wap">
    <ul>
      <li>
        <router-link to="/" active-class="active">
          <em class="home"></em>
          <p>首頁</p>
        </router-link>
      </li>
      <li>
        <router-link to="/send" active-class="active">
          <em class="act"></em>
          <p>發送名片</p>
        </router-link>
      </li>
      <li @click="showShare = true">
        <a href="javascript:void(0);"><em class="buy"></em>
          <p>推薦好友</p>
        </a>
      </li>
      <li>
        <a href="javascript:history.back()"><em class="back"></em>
          <p>回上頁</p>
        </a>
      </li>
    </ul>
  </footer>
  <van-dialog v-model:show="showShareQrcode" title="分享二維碼" :show-cancel-button="true" cancel-button-text="關閉"
    :show-confirm-button="false">
    <div class="qrcode-block">
      <img :src="`${imageUrl}/${user_id}/${user_id}_refer_qrcode.png`" />
    </div>
  </van-dialog>
  <van-share-sheet v-model:show="showShare" title="推薦好友申請UTel名片" :options="options" @select="onSelect" />

</template>

<script>
import { computed, ref } from 'vue';
import { Toast } from 'vant';

import { useStore } from 'vuex';

import { toClipboard } from '@soerenmartius/vue3-clipboard'

export default {
  name: 'Footer',
  setup() {
    const store = useStore()

    const showShare = ref(false)

    const showShareQrcode = ref(false)

    const imageUrl = ref(process.env.VUE_APP_IMAGE_URL)

    const options = [
      { name: '二维码', icon: 'qrcode', key: 'qrcode' },
      { name: '分享連結', icon: 'link', key: 'link' },
    ];

    const onSelect = (option) => {
      if (option.key === 'qrcode') {
        showShareQrcode.value = true
      } else if (option.key === 'link') {
        toClipboard(`https://liff.line.me/${process.env.VUE_APP_LINE_LIFF_ID}/?aid=${store.state.user.userInfo.agent_prefix}`)
        Toast('已放入剪貼簿')
      }
      showShare.value = false
    };

    const user_id = computed(() => {
      return store.state.user.userInfo.user_id
    })

    return {
      options,
      onSelect,
      user_id,
      imageUrl,
      showShare,
      showShareQrcode
    };
  }
}
</script>

<style>
.qrcode-block {
  text-align: center;
}

.footerMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  -webkit-tap-highlight-color: transparent;
  height: 64px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.footerMenu ul {
  margin: auto;
  display: block;
  width: 100%;
  height: 64px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  background-color: #222;
  border-top: 1px solid #cecece;
}

.footerMenu ul li {
  float: left;
  width: 19%;
  height: 100%;
}

.footerMenu.wap li {
  width: 25%;
}

.footerMenu ul li a {
  line-height: 20px;
  text-align: center;
  display: block;
  text-decoration: none;
  padding-top: 2px;
  font-size: 13px;
  position: relative;
  height: 46px;
}

.footerMenu ul li a em {
  margin: 0 auto;
  display: block;
  height: 20px;
  width: 22px;
  margin-top: 3px;
  margin-bottom: 2px;
  background-repeat: no-repeat;
  background-size: 100%;
  border-bottom: 0;
}

.footerMenu ul li a em.home {
  background-image: url(~@/assets/images/f01-1.png);
}

.footerMenu ul li a.active em.home {
  background-image: url(~@/assets/images/f01-2.png);
}

.footerMenu ul li a em.my {
  background-image: url(~@/assets/images/f02-1.png);
}

.footerMenu ul li a.active em.my {
  background-image: url(~@/assets/images/f02-2.png);
}

.footerMenu ul li a em.back {
  background-image: url(~@/assets/images/f04.png);
}

.footerMenu ul li a em.act {
  background-image: url(~@/assets/images/f05-1.png);
}

.footerMenu ul li a.active em.act {
  background-image: url(~@/assets/images/f05-2.png);
}

.footerMenu ul li a em.buy {
  background-image: url(~@/assets/images/f06-1.png);
}

.footerMenu ul li a.active em.buy {
  background-image: url(~@/assets/images/f06-2.png);
}

.footerMenu ul li a p {
  color: #fff;
  opacity: 0.8;
}

.footerMenu ul li a.active p {
  color: #fdaf00;
  opacity: 1;
}
</style>