import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { vant } from "@/plugins/vant";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";

import "@/assets/css/normalize.css";
import "@/assets/css/common.less";
// import './registerServiceWorker'

const vue = createApp(App);

vant(vue);
vue.use(VueClipboard);
vue.use(store).use(router).mount("#app");
