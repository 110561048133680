import { createRouter, createWebHistory } from "vue-router";

import store from "@/store";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import Home from "../views/Home/index.vue";
import Card from "../views/Card/Index.vue";
import Auth from "../views/Auth/Index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/send",
    name: "Send",
    component: () =>
      import(/* webpackChunkName: "send" */ "../views/Send/index.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () =>
      import(/* webpackChunkName: "shop" */ "../views/Shop/index.vue"),
  },
  {
    path: "/shop/inputsn",
    name: "InputSN",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/Shop/Inputsn.vue"),
  },
  {
    path: "/member",
    name: "Member",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/Member/index.vue"),
  },
  {
    path: "/card",
    name: "Card",
    component: Card,
    children: [
      {
        path: "/card/edit",
        name: "CardEdit",
        component: () =>
          import(/* webpackChunkName: "card" */ "../views/Card/Edit"),
        meta: { keepAlive: true },
      },
      {
        path: "/card/notice",
        name: "CardNotice",
        component: () =>
          import(/* webpackChunkName: "card" */ "../views/Card/Notice"),
      },
      {
        path: "/card/video",
        name: "CardVideo",
        component: () =>
          import(/* webpackChunkName: "card" */ "../views/Card/Video"),
        meta: { keepAlive: true },
      },
      {
        path: "/card/preview",
        name: "CardPreview",
        component: () =>
          import(/* webpackChunkName: "card" */ "@/components/Preview"),
        meta: { keepAlive: true },
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    children: [
      {
        path: "/auth/auth",
        name: "Auth",
        component: () => import("../views/Auth/Auth.vue")
      },
      {
        path: "/auth/getauth",
        name: "GetAuth",
        component: () => import("../views/Auth/GetAuth.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "/auth/edit",
        name: "AuthEdit",
        component: () => import("../views/Auth/Edit.vue"),
        meta: { keepAlive: true },
      },
      {
        path: "/auth/preview",
        name: "AuthPreview",
        component: () =>
          import(/* webpackChunkName: "card" */ "@/components/Preview"),
        meta: { keepAlive: true },
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Register/index.vue"),
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Login/index.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Test.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  if (to.path !== "/" && to.path !== "/login" && to.path !== "/register") {
    if (!sessionStorage.getItem("token")) {
      next("/");
    }

    // if(!store.state.user.userInfo){
    //   await store.dispatch('user/getUserInfo')
    // }
  }

  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
