import { getUserInfo, setUserTpl } from "@/api";

export default {
  namespaced: true,
  state: () => ({
    userInfo: null,
  }),
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setCusCard(state, payload) {
      state.userInfo.cus_card = payload;
    },
    setLevel(state, payload) {
      state.userInfo.level = payload;
    },
    setTpl(state, payload) {
      state.userInfo.nc_template = payload;
    },
  },
  actions: {
    async getUserInfo(context) {
      var res = await getUserInfo();
      if (res.code === 200) {
        context.commit("setUserInfo", res.data);
      }
    },
    async setUserTpl(context, payload) {
      var res = await setUserTpl(payload);
      if (res.code === 200) {
        context.commit("setTpl", payload);
      }
    },
  },
};
