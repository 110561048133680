<template>
<Suspense>
  <template #default>
    <router-view/>
  </template>
  <template #fallback>
    Loading
  </template>
</Suspense>
</template>

<script setup>
import { getSiteConfig } from './api'

import { useStore } from 'vuex'

const store = useStore()


</script>
<style lang="less">
#app{
  margin-bottom: 40px;
}
</style>
